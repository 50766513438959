
  /* #Mega Menu Styles
  if you use sass, convert all this additional css to tailwindcss using the 'hack' @apply for all element.style (css properties)
[ https://tailwindcss.com/docs/functions-and-directives/#apply ]
example:
  .mega-menu {
  @apply
  hidden
  left-0
  absolute
  text-left
  w-full;
  }
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  /* .mega-menu {
    opacity:0;
     visibility:hidden; 
     z-index: -900; 
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
    transition: all 0.15s linear 0s;
  }




  

  .toggleable > label:after {
    content: "\25BC";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  } 

  .toggle-input {
    display: none;
    
  }
  .toggle-input:not(checked) ~ .mega-menu {
    opacity:0;
    visibility:hidden;
    z-index: -900;
  } 

  .toggle-input:checked ~ .mega-menu {
    opacity:1;
    visibility:visible;
    z-index: 900;
  }

  .toggle-input:checked + label {
    color: white;
    background: rgb(3, 102, 114);
  }

  .toggle-input:checked ~ label:after {
    content: "\25B2";
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }  */


  .mega-menu {
    opacity: 0;
    visibility: hidden;
    z-index: -900;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;

  }
  
  .mega-menu.open {
    opacity: 1;
    visibility: visible;
    z-index: 900;
  }