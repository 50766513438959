


/* .contact-breadcrump::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8); 
    z-index: 1; 
} */

.breadcrump-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Adjust the color and opacity as needed */
    z-index: 1;
}
.contact-breadcrump{
    width: 100%;
    height: 60vh;
    position: relative;
}


.breadcrump-img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 15%;
    left:40%;
    z-index:2;
}



.hero-text p{
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
}



@media screen and (max-width: 555px) {
    .hero-text{
        position: absolute;
        top: 15%;
        left:40%;
        z-index:2;
    }

    .hero-text h1{
        padding: 10px 20px;
    }

    .hero-text p{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show{
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }

    .contact-breadcrump{
        width: 100%;
        height: 45vh;
        position: relative;
    }
} 

 